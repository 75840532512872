import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css"; 
import './App.scss';
import Home from './page/Home';
import Logo from './page/Logo';
import ReactGA from 'react-ga';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';


function App() {

  const TRACKING_ID = "UA-245700483-1"; // YOUR_OWN_TRACKING_ID
  
  ReactGA.initialize(TRACKING_ID);
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <>
      <title>YoHunt: Kill Zombie, Snatch Nfts, EarnCrypto </title>
      <Router>
        <Routes>
          <Route exact path='/'  element={<Home/>} />
          <Route path='/logo' element={<Logo/>} />
        </Routes>
      </Router>
      {/* <Home/> */}
    </>
  );
}

export default App;
