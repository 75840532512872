import React from 'react';
import Countdown from './Countdown';
// import downIcon from '../../images/arrow-down.png';
import ReactGA from 'react-ga';
import './Banner.scss';

const Banner = () => {
    return (
        <div className="banner-area">
            <div className="banner-bg">
                <video preload="true" autoPlay muted loop playsInline>
                    {/* <source src={banner} type='video/mp4' /> */}
                    <source src="https://ik.imagekit.io/yh/banner-trimmed.mp4?ik-sdk-version=javascript-1.4.3&updatedAt=1665714185499" type='video/mp4' />
                </video>
            </div>
            <div className="banner-shape">
                <div className="shape1"></div>
                <div className="shape2"></div>
            </div>  
            <div className="container" onClick={()=>{window.open('https://www.youtube.com/@yohunt/featured', '_blank'); ReactGA.event({category:'User', action:'youtube_open'})}} href="#" >
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="banner-text text-center">
                            <h1 className='text-white fw-bold'>Mobile Augmented Reality Game</h1>
                            <a className="btn-lg mt-4" >Play & Earn</a>
                        </div>
                    </div>

                    <div className="col-lg-7" >
                        <div className="banner-bil">
                            {/* <div className="bil-item border-phone">
                                <p className='text-white-50'>Current Bid</p>
                                <div className="h4 text-primary mb-0 fw-semibold">5.00 eth</div>
                                <p className='text-white'>$11, 373.53</p>
                            </div> */}
                            <div className="bil-item text-center">
                                <p className='text-white-50'> 
                                    YoKen is available on Uniswap 
                                    <br></br>
                                    Contract 0xec0e083d037be919375c39d13b74856647a03632
                                    </p>
                                {/* <p className='text-white-50'>  </p> */}
                            </div>
                            {/* <div className="bil-item d-grid gap-3 text-center">
                                <a href="#" className="btn-md btn-md-primary">Explore</a>
                                <a href="#" className="btn-md">See Item</a>
                            </div> */}
                        </div>
                    </div>
                    <div className='press-item' >
                        {/* <ul className="press-block justify-flex-start flex-grow-1 gap-4">
                            <div className="press-item">
                                <a className="press-link text-white" onClick={()=> {window.open('https://ik.imagekit.io/yh/YoHunt_White_Paper.pdf?ik-sdk-version=javascript-1.4.3&updatedAt=1667400181235', '_blank'); ReactGA.event({category:'User', action:'whitepaper_open'})}} href="#">Blockchain Magazine</a>
                            </div> 
                            <div className="press-item">
                                <a className="press-link text-white" onClick={()=> {window.open('https://www.youtube.com/@yohunt/featured', '_blank'); ReactGA.event({category:'User', action:'youtube_open'})}} href="#">Gamezebo</a>
                            </div> 
                            <div className="press-item">
                                <a className="press-link text-white" onClick={()=> {window.open('https://github.com/solidproof/projects/blob/main/YoHunt/KYC_Certificate_YoHunt.png', '_blank'); ReactGA.event({category:'User', action:'KYC_open'})}} href="#">Droid Gamers</a>
                            </div> 
                            <div className="press-item">
                                <a className="press-link text-white" onClick={()=> {window.open('https://ik.imagekit.io/yh/SmartContract_Audit_Solidproof_YoHunt.pdf?ik-sdk-version=javascript-1.4.3&updatedAt=1666978327951', '_blank'); ReactGA.event({category:'User', action:'audit_open'})}} href="#">NFT Lately</a>
                            </div> 
                        </ul>  */}
                    </div>
                    <div className="col-lg-12">
                        {/* <div className="down-arrow">
                            <a href="#gameSetting">
                                <img src={downIcon} alt="icon" />
                            </a>
                        </div> */}
                    </div>                
                </div>
            </div>
        </div>
    )
}

export default Banner