import React from 'react'


const Logo = () => {
    return (
        <>
            <img src={'https://ik.imagekit.io/yh/YoHunt-Logo-200x200.png?updatedAt=1678986117888'} onClick={()=> {window.open('https://ik.imagekit.io/yh/YoHunt-Logo-200x200.png?updatedAt=1678986117888', '_blank'); }} href="https://ik.imagekit.io/yh/YoHunt-Logo-200x200.png?updatedAt=1678986117888" alt="logo" />
        </>
    )
}

export default Logo;