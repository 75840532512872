import React from "react";
import './Zombie.scss';
// import React, { Component,useEffect,useRef } from "react";
// import Slider from "react-slick";
// import nextBtn from '../../images/next-btn.png'

const Zombie = () => {
    // const sliderRef = useRef(null);
    // useEffect(()=>{
    //     // console.log(sliderRef.current);
    // }, []);
    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     vertical: true,
    //     verticalSwiping: true,
    //     swipeToSlide: true,  
    //     arrows: false
    // };
    return (
        <div className="zombie-area" id="zombie">
            <div className="zombie-shape">
                <div className="shape1"></div> 
                <div className="shape2"></div> 
            </div>
            <div className="container">
                <div className="h2 section-title fw-semibold text-white text-center" >
                    Zombie location and NFT purchase
                </div>
                <div className="row">
                    <div className="col-lg-5">

                        <div className="zombie-image">
                            <div className="zombie-image-item">
                                <img src="https://ik.imagekit.io/yh/z3.png?ik-sdk-version=javascript-1.4.3&updatedAt=1665606441216" alt="zombie-icon" className='img-fluid' />
                                <div className="h5 text-white mb-2 mt-3">Level 14 Zombie NFT</div>
                                <p className='text-white'>$ 409.00 </p>
                                <p className='text-white'>* example </p>
                            </div>
                            <div className="zombie-image-item">
                                <img src="https://ik.imagekit.io/yh/z2.png?ik-sdk-version=javascript-1.4.3&updatedAt=1665606450738" alt="zombie-icon" className='img-fluid' />
                                <div className="h5 text-white mb-2 mt-3">Level 17 Zombie NFT</div>
                                <p className='text-white'>$ 6,400.00</p>
                                <p className='text-white'>* example</p>
                            </div>
                            <div className="zombie-image-item">
                                <img src="https://ik.imagekit.io/yh/z1.png?ik-sdk-version=javascript-1.4.3&updatedAt=1665606280769" alt="zombie-icon" className='img-fluid' />
                                <div className="h5 text-white mb-2 mt-3">Level 21 Zombie NFT</div>
                                <p className='text-white'>$ 250,000.00</p>
                                <p className='text-white'>* example</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="zombie-content">
                            <div className="zombie-slid">
                                {/* <Slider ref={sliderRef} {...settings}> */}
                                    <div className="zombie-item">
                                        <p>
                                            8 unique Zombies NFTs of level 17 will be made for the first 8 holders that will accumulate 
                                            10 million YoKens each. All those addresses will be able to claim their NFT when they become available.
                                        </p>
                                        <p>
                                            Each Zombie, when successfully hunted, will be placed into players' in-game inventory. 
                                            The first Zombie of each kind opens an opportunity to hunt the next level, and it is required that it 
                                            remains in your inventory to hunt the next level. If a Zombie is not located by any survivor within 24 hours, 
                                            it will relocate to another place in the world. Each next level of zombies has a shorter timer. 
                                        </p>
                                        <p>
                                            NFTs can be sold on the in-game Marketplace. Commissions on all sales go to increasing the value of YoKen, 
                                            thus benefiting all YoKen holders.
                                        </p>
                                        <p>
                                            Special holiday collections will be dropped separately without effect on the game dynamics, They are for esthetic and collection purposes only (Halloween, Christmas, Etc.)
                                        </p>
                                    </div>
                                {/* </Slider> */}
                                {/* <button className="nextButton text-white" 
                                    onClick={()=>sliderRef.current.slickNext()}
                                >
                                    Next <span><img src={nextBtn} alt="icon" /></span>
                                </button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Zombie