import React from 'react';
import icon from '../../images/next-btn.png';
import up from '../../images/up.png';
import './CallToAction.scss';
import ReactGA from 'react-ga';

const CallToAction = () => {
    return (
        <div className="call-action-area">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-7">
                        <div className="text text-center">
                            <div className="h2 mb-5 section-title fw-semibold text-white">
                                   
                            </div>
                            <div className="call-form">
                                <input type="email" name="email" placeholder='info@yohunt.io' />
                                <button type="submit" onClick={()=> {
                                    ReactGA.event({category:'User', action:'email'});
                                    window.open("mailto:info@yohunt.io")
                                    }}><img src={icon} alt="icon" /></button>
                            </div>

                        </div>

                    </div>
                    {/* <div>
                        <img src="https://ik.imagekit.io/yh/partner-bar-2.png?ik-sdk-version=javascript-1.4.3&updatedAt=1665711329004" alt="zombie-icon" className='img-partners' ></img>
                    </div> */}
                </div>
            </div>
            <div className="scroll-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="scroll-content">
                                <a href="#">
                                    <img src={up} alt="icon" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CallToAction