import React from 'react';
import ReactDOM from 'react-dom/client'; 
import App from './App'; 
import ReactGA from 'react-ga';


const TRACKING_ID = "UA-245700483-1"; // YOUR_OWN_TRACKING_ID

ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
 