import React, { useState,useEffect } from "react";
import logo from '../../images/logo.png';
import bar from '../../images/bar.png';
import play from '../../images/play.png';
import './NavBar.scss';
import ReactGA from 'react-ga';

const NavBar = () => {
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 50);
        });
    }, []); 
    return (
        <nav className={scroll ? "navbar navbar-expand-lg fixed-top navBarTop navScroll" : "navbar navbar-expand-lg fixed-top navBarTop"}>
            <div className="container">
                <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                    <img src={bar} alt="icon" />
                </button>
                <a className="navbar-brand d-lg-none" href="#">
                    <img src={logo} alt="logo" />
                </a>
                <div className="paly-icon d-lg-none">
                    <a type="button" className="" data-bs-toggle="modal" data-bs-target="#playModal" href="#">
                        <img src={play} alt="icon" />
                    </a>
                </div>
                <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                            <img src={logo} alt="logo" />
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body align-items-lg-center">
                        <ul className="navbar-nav justify-content-start flex-grow-1 gap-4">
                            <li className="nav-item">
                                <a className="nav-link text-white" onClick={()=> {window.open('https://ik.imagekit.io/yh/YoHunt_White_Paper.pdf?ik-sdk-version=javascript-1.4.3&updatedAt=1667400181235', '_blank'); ReactGA.event({category:'User', action:'whitepaper_open'})}} href="#">White Paper</a>
                            </li> 
                            <li className="nav-item">
                                <a className="nav-link text-white" onClick={()=> {window.open('https://www.youtube.com/@yohunt/featured', '_blank'); ReactGA.event({category:'User', action:'youtube_open'})}} href="#">Videos</a>
                            </li> 
                            {/* <li className="nav-item">
                                <a className="nav-link text-white" onClick={()=> {window.open('https://ik.imagekit.io/yh/YoKenomics.pdf?ik-sdk-version=javascript-1.4.3&updatedAt=1666816781545', '_blank'); ReactGA.event({category:'User', action:'tokenomics_open'})}} href="#">Tokenomics</a>
                            </li> */}
                            <li className="nav-item">
                                <a className="nav-link text-white" onClick={()=> {window.open('https://github.com/solidproof/projects/blob/main/YoHunt/KYC_Certificate_YoHunt.png', '_blank'); ReactGA.event({category:'User', action:'KYC_open'})}} href="#">KYC</a>
                            </li> 
                            <li className="nav-item">
                                <a className="nav-link text-white" onClick={()=> {window.open('https://ik.imagekit.io/yh/SmartContract_Audit_Solidproof_YoHunt.pdf?ik-sdk-version=javascript-1.4.3&updatedAt=1666978327951', '_blank'); ReactGA.event({category:'User', action:'audit_open'})}} href="#">Audit</a>
                            </li> 
                            <li className="nav-item">
                                <a className="nav-link text-white" onClick={()=> {window.open('https://app.uniswap.org/#/tokens/ethereum/0xec0e083d037be919375c39d13b74856647a03632', '_blank'); ReactGA.event({category:'User', action:'Buy_Uniswap'})}} href="#">Get YoKen</a>
                            </li> 
                        </ul> 
                        <div className="mx-auto d-none d-lg-block"> 
                            <a className="navbar-brand" href="#">
                                <img src={logo} alt="logo" />
                            </a>
                        </div>

                        <div className="ms-auto d-none d-lg-block"> 
                            <a type="button" className="btn-md btn-md-primary text-white" data-bs-toggle="modal" data-bs-target="#playModal" href="#" >
                                Join YoHunt
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="playModal" tabIndex="-1" aria-labelledby="playModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content"> 
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>{ReactGA.event({category:'User', action:'wants_to_play'})}}></button> 
                        <div className="modal-body">
                            <div className="h3" >Beta Testers & NFT Artists needed!</div>
                            <p>
                                If you would like to join YoHunt as a beta tester, send us your gamer resume along with your preferred platform 
                                iOS or Android to <a href="mailto:beta@yohunt.io">beta@yohunt.io</a>
                            </p>
                            <hr></hr>
                            <p>
                                Additionally, YoHunt is inviting NFT creators and 3d model artists to contribute to the design of zombie 
                                collections for chosen game levels. In order to participate and receive NTF creation guidelines, 
                                send your references and portfolio to <a href="mailto:nftart@yohunt.io">nftart@yohunt.io</a>. Selected 
                                artists will be rewarded with YoKen digital currency and the opportunity to have a Zombie NFT collection 
                                named after its creator!
                            </p>
                            <hr></hr>
                            <p>
                                * This submittion does not guarantee your acceptance. 
                            </p>
                        </div> 
                    </div>
                </div>
            </div>

        </nav>
    )
}

export default NavBar