import React, { Component } from "react";
// import Slider from "react-slick"; 

import './Weapons.scss'

const Weapons = () => { 
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        responsive: [
            {
            breakpoint: 1024,
                settings: {
                    slidesToShow: 1,  
                    arrows: false,
                }
            },
            {
            breakpoint: 600,
                settings: {
                    slidesToShow: 1, 
                    arrows: false,
                }
            } 
        ]
    };

    return (
        <div className="weapons-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="h2 section-title fw-semibold text-white text-center">
                            Weapons
                        </div>
                        <div className="weapons-slid">
                            {/* <Slider {...settings}> */}
                                <div className="weapons-item">
                                    <div>
                                        {/* <div className="icon"><img src={weaponsImg1} alt="icon" /></div> */}
                                        <div className="icon"><img src={"https://ik.imagekit.io/yh/i1.png?ik-sdk-version=javascript-1.4.3&updatedAt=1666900488056"} alt="icon" /></div>
                                        <div className="h5">Bows & crossbows</div>
                                        <ul>
                                            <li>Player starts with a generic bow and 12 arrows.</li>
                                            <li>Different bows can be purchased at the game store or won in competitions.</li>
                                            <li>Bow shot distance upgrades can be purchased at game store on levels 5/10/15/20/25</li>
                                        </ul> 
                                    </div>
                                </div> 
                                <div className="weapons-item">
                                    <div>
                                        <div className="icon"><img src={"https://ik.imagekit.io/yh/i2.png?ik-sdk-version=javascript-1.4.3&updatedAt=1666900465812"} alt="icon" /></div>
                                        <div className="h5">Armor</div>
                                        <ul>
                                            <li>In Season One most armor is only cosmetic and can be purchased at the game store.</li>
                                            <li>Glasses can be purchased at the game store. They allow to see +2 level of zombies. Hint, ability to see higher level zombies may come in handy if players will cooperate in finding most rare zombies.)</li> 
                                        </ul> 
                                    </div>
                                </div> 
                                <div className="weapons-item">
                                    <div>
                                        <div className="icon"><img src={"https://ik.imagekit.io/yh/i3.png?ik-sdk-version=javascript-1.4.3&updatedAt=1666900477308"} alt="icon" /></div>
                                        <div className="h5">Arrows & Bolts</div>
                                        <ul>
                                            <li>Each player starts with 12 arrows</li>
                                            <li>Once arrow supply is depleted, players can purchase additional arrows at the game store, or obtain free arrows at each partner locations, up to 5 arrows per day, per location. Players can visit multiple locations to pick up their free arrows. </li> 
                                        </ul> 
                                    </div>
                                </div> 
                            {/* </Slider>  */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Weapons