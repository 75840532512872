import React from 'react';
import footerLogo from '../../images/round-logo.png';
import { BsYoutube,BsDiscord,BsTelegram,BsTwitter } from "react-icons/bs";
import './Footer.scss';
import ReactGA from 'react-ga';
const Footer = () => {
    return (
        <footer className="footer-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="footer-content">
                            <div className="footer-logo">
                                <a href="#">
                                    <img src={footerLogo} href="/logo" alt="logo" />
                                </a>
                            </div>
                            <div className="footer-center ms-auto d-lg-flex align-items-center gap-5">
                                
                                <ul className="footer-list d-grid d-lg-flex gap-4 gap-lg-5">
                                    <li><a onClick={()=> {window.open('https://ik.imagekit.io/yh/YoHunt_White_Paper.pdf?ik-sdk-version=javascript-1.4.3&updatedAt=1667400181235', '_blank'); ReactGA.event({category:'User', action:'whitepaper_open'})}} href="#" >White Paper</a></li>
                                    <li><a onClick={()=> {window.open('https://yohunt-logo.s3.amazonaws.com/YoHunt-round-logo.png', '_blank'); ReactGA.event({category:'User', action:'logo_open'})}} href="/logo">Logo</a></li>
                                    <li><a onClick={()=> {window.open('https://github.com/solidproof/projects/blob/main/YoHunt/KYC_Certificate_YoHunt.png', '_blank'); ReactGA.event({category:'User', action:'KYC_open'})}} href="#">KYC</a></li>
                                    <li><a onClick={()=> {window.open('https://ik.imagekit.io/yh/SmartContract_Audit_Solidproof_YoHunt.pdf?ik-sdk-version=javascript-1.4.3&updatedAt=1666978327951', '_blank'); ReactGA.event({category:'User', action:'Audit_open'})}} href="#">Audit</a></li>
                                </ul>

                                <div className="footer-social d-flex gap-3">
                                    <a onClick={()=> {window.open('https://discord.gg/yohunt', '_blank'); ReactGA.event({category:'User', action:'discord_open'})}} href="#"  ><BsDiscord/></a>
                                    <a onClick={()=> {window.open('http://www.youtube.com/channel/UCyTyCEG-6EY-yvTIeJmxwaw', '_blank'); ReactGA.event({category:'User', action:'youtube_open'})}} href="#"><BsYoutube/></a>
                                    <a onClick={()=> {window.open('https://t.me/Yo_Hunt', '_blank'); ReactGA.event({category:'User', action:'telegram_open'})}} href="#"><BsTelegram/></a>
                                    <a onClick={()=> {window.open('https://twitter.com/YoHuntGame', '_blank'); ReactGA.event({category:'User', action:'twitter_open'})}} href="#"><BsTwitter/></a>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </footer>
    )
}

export default Footer