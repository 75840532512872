import React from 'react';
import './Partners.scss';
import ReactGA from 'react-ga';
 
const Partners = () => {
    // const settings = {
    //     dots: false,
    //     infinite: true,
    //     autoplay: true,
    //     autoplaySpeed: 12000,
    //     speed: 12000,
    //     slidesToShow: 4,
    //     slidesToScroll: 1,
    //     initialSlide: 0,
    //     arrows: false,
    //     responsive: [
    //         {
    //         breakpoint: 1024,
    //             settings: {
    //                 slidesToShow: 3,  
    //                 arrows: false,
    //             }
    //         },
    //         {
    //         breakpoint: 600,
    //             settings: {
    //                 slidesToShow: 2, 
    //                 arrows: false,
    //             }
    //         } 
    //     ]
    // };
    return (
        <>
            <div className='partner-item' >
                <img src="https://ik.imagekit.io/yh/Partner_logos.png?ik-sdk-version=javascript-1.4.3&updatedAt=1668188574731" alt="partners"/>
            </div>
            <div className='press-item'>
                <span className="press-item">
                    <a className="press-link text-white" onClick={()=> {window.open('https://blockchainmagazine.net/the-future-of-zombie-games-and-ar-and-crypto-with-yohunt/', '_blank'); ReactGA.event({category:'User', action:'press_open'})}} href="#">Blockchain Magazine</a>
                </span> 
                <span className="press-item">
                    <a className="press-link text-white" onClick={()=> {window.open('https://www.gamezebo.com/news/yohunt-is-an-upcoming-ar-zombie-game-with-nfts-and-its-own-native-cryptocurrency/', '_blank'); ReactGA.event({category:'User', action:'press_open'})}} href="#">Gamezebo</a>
                </span> 
                <span className="press-item">
                    <a className="press-link text-white" onClick={()=> {window.open('https://www.droidgamers.com/news/yohunt-is-like-pokemon-go-with-nft-zombies-and-play-to-earn-gameplay/', '_blank'); ReactGA.event({category:'User', action:'press_open'})}} href="#">Droid Gamers</a>
                </span> 
                <span className="press-item">
                    <a className="press-link text-white" onClick={()=> {window.open('https://nftlately.com/youhunt-the-zombie-p2e-game-you-must-hunt-down-in-2023/', '_blank'); ReactGA.event({category:'User', action:'press_open'})}} href="#">NFT Lately</a>
                </span>  
            </div>
        </>

        // <Slider {...settings}>
        //     <div className='partner-item'><img src="https://ik.imagekit.io/yh/Partner_Logos/uniswap_VUetcX9ws.png?ik-sdk-version=javascript-1.4.3&updatedAt=1666908923252" alt="Uniswap Logo" /></div>
        //     <div className='partner-item'><img src="https://ik.imagekit.io/yh/Partner_Logos/unity-game-engine-logo-hd-png-11663347245k2ja53jvgc_Ba0HAE7UB.png?ik-sdk-version=javascript-1.4.3&updatedAt=1666908923255" alt="Unity Logo"/></div>
        //     <div className='partner-item'><img src="https://ik.imagekit.io/yh/Partner_Logos/CoinMarketCap-Logo_yo19Sftzgw.png?ik-sdk-version=javascript-1.4.3&updatedAt=1666908922457" alt="CoinMarketCap Logo"/></div>
        //     <div className='partner-item'><img src="https://ik.imagekit.io/yh/Partner_Logos/android-logo-integrations-support-web-development-services-14_A6CVoImV5.png?ik-sdk-version=javascript-1.4.3&updatedAt=1666908922451" alt="Android"/></div>
        //     <div className='partner-item'><img src="https://ik.imagekit.io/yh/Partner_Logos/solidproof_Xp9exY7CXQ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1666908922451" alt="SolidProof Logo"/></div>
        //     <div className='partner-item'><img src="https://ik.imagekit.io/yh/Partner_Logos/epic-games-logo-3_a2XJkjWyEf.png?ik-sdk-version=javascript-1.4.3&updatedAt=1666908922447" alt="Epic Games Logo"/></div>
        //     <div className='partner-item'><img src="https://ik.imagekit.io/yh/Partner_Logos/Coingecko_m2NImqMQD.png?ik-sdk-version=javascript-1.4.3&updatedAt=1666908922444" alt="Coin Gecko Logo"/></div>
        //     <div className='partner-item'><img src="https://ik.imagekit.io/yh/Partner_Logos/unicrypt-logo_ZPkxMDc1r.png?ik-sdk-version=javascript-1.4.3&updatedAt=1666908922428" alt="Unicrypt"/></div>
        //     <div className='partner-item'><img src="https://ik.imagekit.io/yh/Partner_Logos/Component_7_y1FITZuvB.png?ik-sdk-version=javascript-1.4.3&updatedAt=1666910675844" alt="Unreal Engine"/></div>
        //     <div className='partner-item'><img src="https://ik.imagekit.io/yh/Partner_Logos/cpp_logo_56Ui9QqKi.png?ik-sdk-version=javascript-1.4.3&updatedAt=1666908922407" alt="C++ Logo"/></div>
        //     <div className='partner-item'><img src="https://ik.imagekit.io/yh/Partner_Logos/Component_6__2__Y2ruCHZNG.png?ik-sdk-version=javascript-1.4.3&updatedAt=1666910210764" alt="ETH logo"/></div>
        //     <div className='partner-item'><img src="https://ik.imagekit.io/yh/Partner_Logos/mobile-app-launch-icon-20__dm8LFh-n.png?ik-sdk-version=javascript-1.4.3&updatedAt=1666908922368" alt="iOS logo"/></div>
        // </Slider>
    )    
}

export default Partners;