import React from "react";
// import React, { Component,useEffect,useRef } from "react"
// import Slider from "react-slick";
// import nextBtn from '../../images/next-btn.png'
import './GameSetting.scss';  
 
const GameSetting = () => { 
    // const sliderRef = useRef(null);
    // useEffect(()=>{
    //     // console.log(sliderRef.current);
    // }, []);
    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     vertical: true,
    //     verticalSwiping: true,
    //     swipeToSlide: true,  
    //     arrows: false
    // };
    return (
        <div className="setting-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 d-lg-none">
                        {/* <div className="setting-img mb-5">
                            <img src={'https://ik.imagekit.io/yh/setting-bg_1_3iqCZ9dCm.png?ik-sdk-version=javascript-1.4.3&updatedAt=1666294675328'} alt="image" className="responsive" />
                        </div> */}
                    </div>
                    <div className="col-lg-10 offset-lg-1">
                        <div className="setting-slider-content">
                            <div className="h2 section-title text-white fw-semibold">
                                Season One Game Setting
                            </div>
                            <div className="setting-item">

                                <p>
                                    Queen E., the all powerful tyrant of our post-apocalyptic landscape, was inspecting her 
                                    bioweapons lab when an explosion occurred mutating Her and her ministers into zombies.  
                                    Even with her unlimited resources, she was unable to reverse her transformation, so she 
                                    decided to make the world into her new image.  The Queen created a deviant plan to mutate 
                                    the world's population by infecting the population gradually in order to control the transition 
                                    effects from human to zombie.   Her plan occurred in stages, exposing her subjects to the 
                                    bioweapon in waves, each wave affecting a greater segment of the global population.  The Queen’s 
                                    master plan contained only one flaw, mutation of the virus.  Each time the Queen released the 
                                    virus into its new hosts, the progressive waves mutated slightly, making zombies unique in character 
                                    and ability.  And each wave, while increasing in its scope of infection, the transformative effects 
                                    decreased, thereby making the zombies progressively weaker in strength, speed, and stamina which 
                                    make them vulnerable to be hunted by the survivors. 
                                </p>
                                <br></br>
                                <p>
                                    You are one of those survivors ...
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GameSetting