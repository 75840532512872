import React from 'react' 

import './GameTeaser.scss'

const GameTeaser = () => { 
    return (
        <div className="game-teaser-area">
            <div className="game-teaser-bg">
                <div className="game-teaser-bg-shadow"></div>
                <video preload="true" autoPlay muted loop playsInline >
                    <source src="https://ik.imagekit.io/yh/teaser-bg-1200x674.mp4?ik-sdk-version=javascript-1.4.3&updatedAt=1665671928528" type='video/mp4' />
                </video>
            </div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="text-center">
                            <div className="h2 section-title text-white fw-semibold">
                                   <br></br>
                                   <br></br>
                                   Coming Soon
                                   <br></br>
                                   <br></br>
                                   <br></br>
                                   <br></br>
                                   <br></br>
                                   <br></br>
                                  
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-10">
                        <div className="game-video text-center mt-5"> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GameTeaser